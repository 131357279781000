import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./contact.module.css"
import Typo from "../components/typo"

const Contact = () => {
  const [hamburger, setHamburger] = useState("default")

  useEffect(() => {
    const handler = () => {
      setHamburger(window.innerWidth < 1024 ? "default" : "invert")
    }

    handler()
    window.addEventListener("resize", handler)

    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [])

  return (
    <Layout hamburger={hamburger}>
      <SEO title="Contact" />

      <div className={styles.root}>
        <div className={styles.image}></div>
        <div className={styles.text}>
          <div className={styles.text__inner}>
            <Typo type="title500" component="h1">
              Contact
            </Typo>

            <div className={styles.group}>
              <Typo type="title600" component="h2">
                Hong Kong
              </Typo>
              <dl>
                <Typo type="text500" component="dt">
                  Phone
                </Typo>
                <Typo type="text300" component="dd">
                  <a href="tel:+85237026919">+852 3702 6919</a>
                </Typo>
              </dl>
              <dl>
                <Typo type="text500" component="dt">
                  Email
                </Typo>
                <Typo type="text300" component="dd">
                  <a href="mailto:mimosa.lee@ultd.com.hk">
                    mimosa.lee@ultd.com.hk
                  </a>
                </Typo>
              </dl>
              <dl>
                <Typo type="text500" component="dt">
                  Office
                </Typo>
                <Typo type="text300" component="dd">
                  Unit 1406, 14/F Blk A, Hoi Luen Industrial Centre, 55 Hoi Yuen
                  Road, Kwun Tong, KLN, HK
                </Typo>
              </dl>
            </div>

            <div className={styles.group}>
              <Typo type="title600" component="h2">
                United Kingdom
              </Typo>
              <dl>
                <Typo type="text500" component="dt">
                  Phone
                </Typo>
                <Typo type="text300" component="dd">
                  <a href="tel:+44 783 241 6869">+44 783 241 6869</a>
                </Typo>
              </dl>
              <dl>
                <Typo type="text500" component="dt">
                  Email
                </Typo>
                <Typo type="text300" component="dd">
                  <a href="mailto:jeff.kong@ultd.com.hk">
                    jeff.kong@ultd.com.hk
                  </a>
                </Typo>
              </dl>
              <dl>
                <Typo type="text500" component="dt">
                  Office
                </Typo>
                <Typo type="text300" component="dd">
                  Ascent House 35, Boulevard Drive, London NW9 5QZ
                </Typo>
              </dl>
            </div>
          </div>

          <Typo type="text" component="div" className={styles.foot}>
            &copy; ULTD Ltd.
          </Typo>
        </div>
        <div className={styles.map}></div>
      </div>
    </Layout>
  )
}

export default Contact
